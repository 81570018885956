import React from 'react'

export default function about() {
    return (
        <div className="container px-5 pd50">
            <h2 className="text-center">About Us</h2>
            <p className="text-center">
            <b>Shreevanshholidays</b> a well-known tour operating company, 
            We are having more than 15+ years of Experience with Leading Travel Industries <br/>Like SOTC
            We offer a complete travel management, in other words everything from planning to execution.
            <p>We Design Custmise Tour Pakages For Our Internationa Client </p>
            </p>
        </div>
    )
}
