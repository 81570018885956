import image1 from '../images/mount-kailash-shreevanshholidays.jpg';
import image2 from '../images/shreevanshholidays.jpg';
import image3 from '../images/leh-shreevanshholidays.jpg';
import image4 from '../images/maldives-shreevanshholidays.jpg';


import React, { Component } from 'react'  
import Carousel from 'react-bootstrap/Carousel'  
export class BootstrapCarousel extends Component {  
        render() {  
                return (  
                        <div>  
                    
                         <div className='mx-auto' >  
                         <Carousel>  
                         <Carousel.Item>  
                         <img className="d-block w-100"   src={image1}  />  
                           <Carousel.Caption>  
                             <h2>Welcome To Shreevanshholidays</h2>
                             <h3>Travel with Safty </h3>  
                                 </Carousel.Caption>  
                                 </Carousel.Item  >  
                                 <Carousel.Item>  
                                 <img className="d-block w-100"  src={image2}    />  
                                   <Carousel.Caption>  
                                   <h2>Beautiful Kashmir</h2> 
                                   <h3>Travel with Safty </h3>  
                                   </Carousel.Caption>  
                                         </Carousel.Item>  
                                       <Carousel.Item>  
                                       <img  className="d-block w-100"  src={image3}   />  
                                        <Carousel.Caption>  
                                        <h2>Divine Hike</h2> 
                                        <h3>Travel with Safty </h3>  
                                          </Carousel.Caption>  
                                         </Carousel.Item>  
                                         <Carousel.Item>  
                                       <img  className="d-block w-100"  src={image4}   />  
                                        <Carousel.Caption>  
                                        <h2>Maldives holidays</h2> 
                                        <h3>Travel with Safty </h3>  
                                          </Carousel.Caption>  
                                         </Carousel.Item>
                                        </Carousel> 
                                         

                                       
                                </div>  
                        </div>  
                )  
        }  
}  
export default BootstrapCarousel  