import React from 'react'

function footer() {
    return (
        <footer className="footer py-5 bg-black">
            <div className="container px-5"><p className="m-0 text-center text-white small">
                <h2>Jaipur Office</h2>
                <p>Email Id: info@shreevanshholidays.com</p>
                <p>Call Us: +91-9660760160</p>
                Copyright &copy; 2019 - 2021 All Right Reserved</p></div>
        </footer>
    )
}

export default footer
