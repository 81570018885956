import React from 'react'
import domistic1 from '../images/domistic1.jpg';
import domistic2 from '../images/domistic2.jpg';
import domistic3 from '../images/domistic3.jpg';
import domistic4 from '../images/domistic4.jpg';
import domistic5 from '../images/domistic5.jpg';
import domistic6 from '../images/domistic6.jpg';


export default function card2() {
    return (
        <div>
                <div className="container px-5">
                    <p><br/></p><p><br/></p>
                    <h2 className="text-center">Domestic Tour Packages</h2>
                    <p>&nbsp;</p>
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-4 order-lg-4">
                                    <div className="card">
                                            <img className="card-img-top" src={domistic1} alt="Maldive" />
                                            <div className="card-body">
                                                    <h5 className="card-title">Andaman Islands</h5>
                                                    <p className="card-text">Starting@ Rs.28 200.00</p>
                                                    <a href="https://api.whatsapp.com/send?phone=919660760160&text=Hi%0AI need Package Details" className="btn btn-success">Book Now</a>
                                            </div>
                                    </div>
                            </div>
                            <div className="col-lg-4 order-lg-4">
                                    <div className="card">
                                            <img className="card-img-top" src={domistic2} alt="dubai" />
                                            <div className="card-body">
                                                    <h5 className="card-title">Goa</h5>
                                                    <p className="card-text">Starting@ Rs.14 500.00</p>
                                                    <a href="https://api.whatsapp.com/send?phone=919660760160&text=Hi%0AI need Package Details" className="btn btn-success">Book Now</a>
                                            </div>
                                    </div>
                            </div>
                            <div className="col-lg-4 order-lg-4">
                                    <div className="card">
                                            <img className="card-img-top" src={domistic3} alt="eiffel" />
                                            <div className="card-body">
                                                    <h5 className="card-title"> North East</h5>
                                                    <p className="card-text">Starting@ Rs.31 900.00</p>
                                                    <a href="https://api.whatsapp.com/send?phone=919660760160&text=Hi%0AI need Package Details" className="btn btn-success">Book Now</a>
                                            </div>
                                    </div>
                            </div>
                        </div>
                        <p>&nbsp;</p>
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-4 order-lg-4">
                                    <div className="card">
                                            <img className="card-img-top" src={domistic4} alt="Maldive" />
                                            <div className="card-body">
                                                    <h5 className="card-title">Kerala </h5>
                                                    <p className="card-text">Starting@ Rs.14 200.00</p>
                                                    <a href="https://api.whatsapp.com/send?phone=919660760160&text=Hi%0AI need Package Details" className="btn btn-success">Book Now</a>
                                            </div>
                                    </div>
                            </div>
                            <div className="col-lg-4 order-lg-4">
                                    <div className="card">
                                            <img className="card-img-top" src={domistic5} alt="dubai" />
                                            <div className="card-body">
                                                    <h5 className="card-title">Himachal</h5>
                                                    <p className="card-text">Starting@ Rs.20 899.00</p>
                                                    <a href="https://api.whatsapp.com/send?phone=919660760160&text=Hi%0AI need Package Details" className="btn btn-success">Book Now</a>
                                            </div>
                                    </div>
                            </div>
                            <div className="col-lg-4 order-lg-4">
                                    <div className="card">
                                            <img className="card-img-top" src={domistic6} alt="eiffel" />
                                            <div className="card-body">
                                                    <h5 className="card-title">Rajasthan </h5>
                                                    <p className="card-text">Starting@ Rs.35 900.00</p>
                                                    <a href="https://api.whatsapp.com/send?phone=919660760160&text=Hi%0AI need Package Details" className="btn btn-success">Book Now</a>
                                            </div>
                                    </div>
                            </div>
                        </div>
                 </div>
        </div>  
    )
}
