import React from 'react'
import hotel from '../images/hotel.gif';
function section2() {
    return (
        <section>
        <div className="container px-5">
            <div className="row gx-5 align-items-center">
                <div className="col-sm-12 col-lg-6">
                    <div><img className="img-fluid rounded-circle" src={hotel} alt="..." /></div>
                </div>
                <div className="col-sm-12 col-lg-6">
                   
                        <h2 className="display-4">Hotel Deals</h2>
                        <p>Get the Best Accommodation With Us, also we provide 24/7 support via phone or email.</p>
                        <ul className="listu">
                                <li>Exclusive rates</li>                               
                                <li>8 lac+ Hotels properties</li>
                                <li>Best-in-class free-cancellation policies</li>
                                <li>Exclusive discounts</li>
                                <li>Easy & quick post booking modifications</li>
                        </ul>

                  
                </div>
            </div>
        </div>
    </section>
    )
}

export default section2
