import React from 'react'

import './App.css';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/navbar';
import Aboutus from './components/about';
import Cardblocks from './components/card';
import Cardblocks2 from './components/card2';
import Section1 from './components/section1';
import Section2 from './components/section2';

import Footer from './components/footer';
import '../node_modules/bootstrap/js/src/carousel';
import BootstrapCarousel from './components/BootstrapCarousel'  
import about from './components/about';

function App() {

 
  
   

  return <>
       <Navbar/>
       <div className="App">  
      <BootstrapCarousel></BootstrapCarousel>  
      </div>
       <Aboutus/>
       <Cardblocks/>
       <Cardblocks2/>
       <Section1/>
       <Section2/>
       <Footer/> 
  </>;
}

export default App;
