import React from 'react';
import logo from '../images/logonew.png';
function navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
        <div className="container">
            <a className="navbar-brand" href="#page-top">
                <img src={logo} className="logo" alt="" /></a>
                <a className="wbstn" href="https://api.whatsapp.com/send?phone=919660760160&text=Hi%0AI need Package Details">
                &nbsp;<i class="fa fa-whatsapp my-float"></i> 
                &nbsp; Whats App for Detail</a>
        </div>
    </nav>
  
    )
}

export default navbar
