import React from 'react'
import visa from '../images/map.gif';

function section1() {



    return (
   
       <>
        <section id="scroll">
        <div className="visa container px-5">
            <div className="row gx-5 align-items-center">
                <div className="col-sm-12 col-lg-6 order-lg-2">
                    <div><img src={visa} alt="..." /></div>
                </div>
                <div className="col-sm-12 col-lg-6 order-lg-1">
                   
                        <h2 className="display-4">Visa and Airlines  </h2>
                        <p>We are an expert visa consultant focusing on providing quick and easy services to all your travelling needs.Our visa consultants provide excellent customer service</p>
                         <ul className="listu">
                            <li>e-Tourist Visa</li>
                            <li>e-Business Visa</li>
                            <li>e-Conference Visa</li>
                            <li>e-Medical Visa</li>
                            <li>e-Medical Attendent Visa</li>
                            <li>e-Emergency X-Misc Visa</li>
                         </ul>
               
                </div>
            </div>
        </div>
        </section>
      </>
    )
}

export default section1
