import React from 'react'
import cardpic1 from '../images/maldive-pic.jpg';
import dubai from '../images/dubai.jpg';
import eiffel from '../images/eiffel.jpg';


export default function card() {
    return (
        <div>
                <div className="container px-5">
                    <h2 className="text-center">International Tour Packages</h2>
                    <p>&nbsp;</p>
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-4 order-lg-4">
                                    <div className="card">
                                            <img className="card-img-top" src={cardpic1} alt="Maldive" />
                                            <div className="card-body">
                                                    <h5 className="card-title">Maldives Packages</h5>
                                                    <p className="card-text">Starting@ Rs.45 200.00</p>
                                                    <a href="https://api.whatsapp.com/send?phone=919660760160&text=Hi%0AI need Package Details" className="btn btn-success">Book Now</a>
                                            </div>
                                    </div>
                            </div>
                            <div className="col-lg-4 order-lg-4">
                                    <div className="card">
                                            <img className="card-img-top" src={dubai} alt="dubai" />
                                            <div className="card-body">
                                                    <h5 className="card-title">Dubai Packages</h5>
                                                    <p className="card-text">Starting@ Rs.15 899.00</p>
                                                    <a href="https://api.whatsapp.com/send?phone=919660760160&text=Hi%0AI need Package Details" className="btn btn-success">Book Now</a>
                                            </div>
                                    </div>
                            </div>
                            <div className="col-lg-4 order-lg-4">
                                    <div className="card">
                                            <img className="card-img-top" src={eiffel} alt="eiffel" />
                                            <div className="card-body">
                                                    <h5 className="card-title">Thailand Packages</h5>
                                                    <p className="card-text">Starting@ Rs.55 900.00</p>
                                                    <a href="https://api.whatsapp.com/send?phone=919660760160&text=Hi%0AI need Package Details" className="btn btn-success">Book Now</a>
                                            </div>
                                    </div>
                            </div>
                        </div>
                 </div>
        </div>  
    )
}
